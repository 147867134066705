import React from "react"
import "./404.module.scss"
import Layout from "../components/layout"
import TopSection from "../components/topsection"
import { graphql } from "gatsby"

const NotFoundPage = ({ data, location, lang }) => (
  <Layout location={location} lang={lang}>
    <TopSection img={data.img.childImageSharp.fluid} title={lang === "fi" ? ("SH-Maalaus OY") : ("SH-Måleri AB")} styleClass="proposal-background" location={location} lang={lang} />
    <div className="container mx-auto text-center">
      <h1 className="heading py-5 text-uppercase">{lang === "fi" ? ("Hopaista vaan") : ("Ibland händer det")}</h1>
      <p className="paragraph pb-5 lead">{lang === "fi" ? ("Etsimääsi sivua ei löytynyt") : ("Sidan du letade efter kunde inte hittas")}</p>
    </div>
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default NotFoundPage
